<template>
  <div class="pricing">
    <vs-tabs v-if="isTabsLoaded" class="pricing__tabs" v-model="activeTabIndex" :key="$i18n.locale">
      <vs-tab icon="border_color" :label="$t('routes.pricingPlans')">
        <AppSumoPlan />
      </vs-tab>

      <vs-tab icon-pack="feather" icon="icon-star" :label="$t('routes.vega')">
        <Vega :autoGetStarted="autoVegaGetStarted" :hasQuerySourceVega="hasQuerySourceVega" />
      </vs-tab>

      <vs-tab :label="$t('routes.gptConnect')" class="gpt-tab">
        <GptConnectPricingPlans />
      </vs-tab>

      <vs-tab icon="border_color" :label="$t('routes.payment')">
        <PricingSubscription @setActiveTabIndex="activeTabIndex = $event" />
      </vs-tab>

      <vs-tab icon-pack="feather" icon="icon-file" :label="$t('routes.invoices')">
        <PricingInvoices />
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import AppSumoPlan from './AppSumoPlan.vue'
import PricingInvoices from '../plans/PricingInvoices'
import Vega from '../../vega/subscription/Vega.vue'
import PricingSubscription from '../plans/PricingSubscriptionNew.vue'
import GptConnectPricingPlans from '../../gpt-connect/subscription/GptConnectPricingPlans.vue'

export default {
  data: () => ({
    activeTabIndex: 0,
    isTabsLoaded: false,
    autoVegaGetStarted: false,
    hasQuerySourceVega: false
  }),

  components: {
    AppSumoPlan,
    PricingInvoices,
    Vega,
    PricingSubscription,
    GptConnectPricingPlans
  },

  mounted() {
    this.hasQuerySourceVega = false

    this.useRouteParams()

    if (this.$route && this.$route.query && this.$route.query.source && this.$route.query.source === 'vega') {
      this.hasQuerySourceVega = true
      this.activeTabIndex = 3
    }

    this.isTabsLoaded = true
  },
  methods: {
    useRouteParams() {
      if (this.$route.params.action) {
        switch (this.$route.params.action) {
          case 'activate-vega': {
            setTimeout(() => {
              this.activeTabIndex = 2

              this.$nextTick(() => {
                this.$serverBus.$emit('hide-ui-locked-status')
              })
            }, 200)

            break
          }

          case 'activate-vega-get-started': {
            setTimeout(() => {
              this.autoVegaGetStarted = true
              this.activeTabIndex = 2

              this.$nextTick(() => {
                this.$serverBus.$emit('hide-ui-locked-status')
              })
            }, 200)

            break
          }

          case 'show-invoices':
            setTimeout(() => {
              this.activeTabIndex = 4

              this.$nextTick(() => {
                this.$serverBus.$emit('hide-ui-locked-status')
              })
            }, 200)

            break
          case 'update-payment-method':
            this.activeTabIndex = 0
            setTimeout(() => {
              this.$nextTick(() => {
                this.activeTabIndex = 3
                this.$serverBus.$emit('hide-ui-locked-status')
              })
            }, 1000)

            break
        }
      }
    }
  }
}
</script>

<style lang="scss">
$main-color: rgb(18, 89, 141);

.pricing {
  &__tabs {
    .vs-tabs {
      &--content {
        padding: 20px 0 0;
      }

      &--ul {
        box-shadow: none;
      }

      &--li {
        span {
          font-size: 16px;
        }
        &.activeChild {
          .vs-tabs--btn {
            color: #275D73 !important;
          }
        }
      }
    }
  }

  &__heading {
    text-align: center;
    color: #262629;

    &__title {
      color: #262629;
      font-size: 30px;
      font-weight: 600;
      line-height: 38px;
    }

    &__description {
      margin-top: 15px;
      font-size: 16px;
    }
  }
}
</style>
