var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pricing" },
    [
      _vm.isTabsLoaded
        ? _c(
            "vs-tabs",
            {
              key: _vm.$i18n.locale,
              staticClass: "pricing__tabs",
              model: {
                value: _vm.activeTabIndex,
                callback: function ($$v) {
                  _vm.activeTabIndex = $$v
                },
                expression: "activeTabIndex",
              },
            },
            [
              _c(
                "vs-tab",
                {
                  attrs: {
                    icon: "border_color",
                    label: _vm.$t("routes.pricingPlans"),
                  },
                },
                [_c("AppSumoPlan")],
                1
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-star",
                    label: _vm.$t("routes.vega"),
                  },
                },
                [
                  _c("Vega", {
                    attrs: {
                      autoGetStarted: _vm.autoVegaGetStarted,
                      hasQuerySourceVega: _vm.hasQuerySourceVega,
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-tab",
                {
                  staticClass: "gpt-tab",
                  attrs: { label: _vm.$t("routes.gptConnect") },
                },
                [_c("GptConnectPricingPlans")],
                1
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    icon: "border_color",
                    label: _vm.$t("routes.payment"),
                  },
                },
                [
                  _c("PricingSubscription", {
                    on: {
                      setActiveTabIndex: function ($event) {
                        _vm.activeTabIndex = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-file",
                    label: _vm.$t("routes.invoices"),
                  },
                },
                [_c("PricingInvoices")],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }