<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    class="price-list__plans-list__item__body__options-list__item__icon"
  >
    <path
      d="M8.43 8.84C8.64 8.45 8.805 8 8.91 7.505C9.06 6.8 9.06 6.11 9 5.495L7.155 5.555C7.215 6.08 7.2 6.575 7.035 7.205L7.005 7.325L5.445 5.645C6.495 5.045 7.425 4.4 7.425 2.885C7.425 1.265 6.09 0.304999 4.35 0.304999C2.475 0.304999 1.365 1.445 1.365 2.96C1.365 3.875 1.8 4.535 2.28 5.06L2.535 5.33L2.49 5.36C1.5 5.93 0.555 6.62 0.555 8.15C0.555 9.965 1.905 11.195 4.38 11.195C5.43 11.195 6.39 10.895 7.155 10.325L7.785 11H10.425L8.43 8.84ZM4.41 2.045C4.995 2.045 5.415 2.45 5.415 3.005C5.415 3.755 4.815 4.025 4.245 4.355L4.095 4.19C3.735 3.8 3.375 3.47 3.375 2.975C3.375 2.405 3.825 2.045 4.41 2.045ZM4.44 9.455C3.42 9.455 2.64 9.05 2.64 8.09C2.64 7.415 3.075 6.995 3.72 6.605L5.94 9.005C5.505 9.305 4.995 9.455 4.44 9.455Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'ThinCheckIcon',
  props: {
    color: {
      type: String,
      default: '#42B5FF'
    }
  }
}
</script>
