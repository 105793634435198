<template>
  <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.98015 8.60447C3.22017 8.85762 3.5466 9 3.8853 9C3.90196 9 3.91863 9 3.93363 8.99824C4.29036 8.98418 4.62542 8.81367 4.85691 8.52884L9.97734 2.22822C10.4374 1.66228 10.3757 0.809449 9.83899 0.324526C9.30401 -0.158935 8.49541 -0.095647 8.03403 0.470453L3.81756 5.71293L2.18542 4.04214C1.68538 3.51474 0.875441 3.51474 0.375132 4.04214C-0.125177 4.56953 -0.124911 5.42378 0.375132 5.95145L2.98015 8.60447Z" :fill="color" />
  </svg>
</template>

<script>
export default {
  name: 'ThinCheckIcon',
  props: {
    color: {
      type: String,
      default: '#42B5FF'
    }
  }
}
</script>
